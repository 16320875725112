/* ==========================================================================
   ==========================================================================
   ==========================================================================

   RESET

   ==========================================================================
   ==========================================================================
   ========================================================================== */

   html, body, div, span, applet, object, iframe, h1, h2, h3, h3, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {    margin: 0;  padding: 0; border: 0;  font-size: 100%;    font: inherit;  vertical-align: baseline;}/* HTML5 display-role reset for older browsers */article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {    display: block;}body {  line-height: 1;}ol, ul {    list-style: none;}blockquote, q {   quotes: none;}blockquote:before, blockquote:after,q:before, q:after {   content: '';    content: none;}table {  border-collapse: collapse;  border-spacing: 0;}




   /* ==========================================================================
      ==========================================================================
      ==========================================================================
   
      GLOBAL
   
      ==========================================================================
      ==========================================================================
      ========================================================================== */
   
   /*   @font-face {    font-family: 'circ';    src: url('fonts/circular-book.eot');    src: url('fonts/circular-book.eot?#iefix') format('embedded-opentype'),        url('fonts/circular-book.woff2') format('woff2'),        url('fonts/circular-book.woff') format('woff'),        url('fonts/circular-book.ttf') format('truetype'),        url('fonts/circular-book.svg#circular-book') format('svg');    font-weight: normal;    font-style: normal;}
   
      @font-face {    font-family: 'circ';    src: url('fonts/circular-medium.eot');    src: url('fonts/circular-medium.eot?#iefix') format('embedded-opentype'),        url('fonts/circular-medium.woff2') format('woff2'),        url('fonts/circular-medium.woff') format('woff'),        url('fonts/circular-medium.ttf') format('truetype'),        url('fonts/circular-medium.svg#circular-medium') format('svg');    font-weight: bold;    font-style: normal;}
   
      @font-face {    font-family: 'circ';    src: url('fonts/circular-black.eot');    src: url('fonts/circular-black.eot?#iefix') format('embedded-opentype'),        url('fonts/circular-black.woff2') format('woff2'),        url('fonts/circular-black.woff') format('woff'),        url('fonts/circular-black.ttf') format('truetype'),        url('fonts/circular-black.svg#circular-black') format('svg');    font-weight: 900;    font-style: normal;}
      */
   
   
      @font-face {
       font-family: 'Monument';
       src: url("fonts/MonumentExtended-Ultrabold.eot");
       src: url("fonts/MonumentExtended-Ultrabold.eot?#iefix") format("embedded-opentype"), url("fonts/MonumentExtended-Ultrabold.woff2") format("woff2"), url("fonts/MonumentExtended-Ultrabold.woff") format("woff"), url("fonts/MonumentExtended-Ultrabold.ttf") format("truetype"), url("fonts/MonumentExtended-Ultrabold.svg#MonumentExtended-Ultrabold") format("svg");
       font-weight: 800;
       font-style: normal
   }
   
   @font-face {
       font-family: 'Monument';
       src: url("fonts/MonumentExtended-Regular.eot");
       src: url("fonts/MonumentExtended-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/MonumentExtended-Regular.woff2") format("woff2"), url("fonts/MonumentExtended-Regular.woff") format("woff"), url("fonts/MonumentExtended-Regular.ttf") format("truetype"), url("fonts/MonumentExtended-Regular.svg#MonumentExtended-Regular") format("svg");
       font-weight: normal;
       font-style: normal
   }
   
   
   
   @font-face {
       font-family: 'sharp';
       src: url('fonts/SharpGrotesk-Book20.eot');
       src: url('fonts/SharpGrotesk-Book20.eot?#iefix') format('embedded-opentype'),
       url('fonts/SharpGrotesk-Book20.woff2') format('woff2'),
       url('fonts/SharpGrotesk-Book20.woff') format('woff'),
       url('fonts/SharpGrotesk-Book20.ttf') format('truetype');
       font-weight: normal;
       font-style: normal;
   }
   
   @font-face {
       font-family: 'sharp';
       src: url('fonts/SharpGrotesk-Light20.eot');
       src: url('fonts/SharpGrotesk-Light20.eot?#iefix') format('embedded-opentype'),
       url('fonts/SharpGrotesk-Light20.woff2') format('woff2'),
       url('fonts/SharpGrotesk-Light20.woff') format('woff'),
       url('fonts/SharpGrotesk-Light20.ttf') format('truetype');
       font-weight: 200;
       font-style: normal;
   }
   
   @font-face {
       font-family: 'sharp';
       src: url('fonts/SharpGrotesk-Medium20.eot');
       src: url('fonts/SharpGrotesk-Medium20.eot?#iefix') format('embedded-opentype'),
       url('fonts/SharpGrotesk-Medium20.woff2') format('woff2'),
       url('fonts/SharpGrotesk-Medium20.woff') format('woff'),
       url('fonts/SharpGrotesk-Medium20.ttf') format('truetype');
       font-weight: 600;
       font-style: normal;
   }
   
   @font-face {
       font-family: 'sharp';
       src: url('fonts/SharpGrotesk-Bold20.eot');
       src: url('fonts/SharpGrotesk-Bold20.eot?#iefix') format('embedded-opentype'),
       url('fonts/SharpGrotesk-Bold20.woff2') format('woff2'),
       url('fonts/SharpGrotesk-Bold20.woff') format('woff'),
       url('fonts/SharpGrotesk-Bold20.ttf') format('truetype');
       font-weight: 800;
       font-style: normal;
   }
   
   
   
   
   html, body {height:100%; -webkit-touch-callout: none; -webkit-backface-visibility: hidden; background: #101010;}
   
   body{font-family: 'sharp', Arial, Verdana, sans-serif;font-weight: 400; overflow-x: hidden;}
   
   #root { width: 100%; height: 100%;display: block; position: absolute; z-index: 0; background: #202020; }
   
   
   .cursor { position: absolute; background: #FFF; width: 20px; height: 20px; margin: -10px 0 0 -10px; border-radius: 50%; will-change: transform; user-select: none; pointer-events: none; z-index: 10000;  }
   
   .clr {clear: both;}
   
   .floatl {float: left;}
   
   .floatr {float: right;}
   
   .mask {display: none !important;}
   
   .preload {pointer-events: none;}
   
   .intro {width: 100%; height:100%; position: fixed; top:0; text-align: center; z-index: 99999; }
   .content-logo {position: absolute; top: 50%; left: 0; transform:translate(0%, -50%); width: 100%; }
   .intro-logo .content { -webkit-text-stroke: 2px #FFF; color:transparent; ; font-family: "Monument"; font-weight: 800; font-size: 100px; letter-spacing: 0.05rem;  }
   .intro-logo:nth-child(16) .content {color:#FFF; -webkit-text-stroke: 0 #FFF;}
   .intro-logo:nth-child(14) .content, .intro-logo:nth-child(18) .content {height: 50px; overflow: hidden;}
   
   .intro-logo:nth-child(1) .content, .intro-logo:nth-child(31) .content {animation: intro-logo-anim 0s 0s both;}
   .intro-logo:nth-child(2) .content, .intro-logo:nth-child(30) .content {animation: intro-logo-anim 0s .1s both;}
   .intro-logo:nth-child(3) .content, .intro-logo:nth-child(29) .content {animation: intro-logo-anim 0s .2s both;}
   .intro-logo:nth-child(4) .content, .intro-logo:nth-child(28) .content {animation: intro-logo-anim 0s .3s both;}
   .intro-logo:nth-child(5) .content, .intro-logo:nth-child(27) .content {animation: intro-logo-anim 0s .4s both;}
   .intro-logo:nth-child(6) .content, .intro-logo:nth-child(26) .content {animation: intro-logo-anim 0s .5s both;}
   .intro-logo:nth-child(7) .content, .intro-logo:nth-child(25) .content {animation: intro-logo-anim 0s .6s both;}
   .intro-logo:nth-child(8) .content, .intro-logo:nth-child(24) .content {animation: intro-logo-anim 0s .7s both;}
   .intro-logo:nth-child(9) .content, .intro-logo:nth-child(23) .content {animation: intro-logo-anim 0s .8s both;}
   .intro-logo:nth-child(10) .content, .intro-logo:nth-child(22) .content {animation: intro-logo-anim 0s .9s both;}
   .intro-logo:nth-child(11) .content, .intro-logo:nth-child(21) .content {animation: intro-logo-anim 0s 1s both;}
   .intro-logo:nth-child(12) .content, .intro-logo:nth-child(20) .content {animation: intro-logo-anim 0s 1.1s both;}
   .intro-logo:nth-child(13) .content, .intro-logo:nth-child(19) .content {animation: intro-logo-anim 0s 1.2s both;}
   .intro-logo:nth-child(14) .content, .intro-logo:nth-child(18) .content {animation: intro-logo-anim 0s 1.3s both;}
   .intro-logo:nth-child(15) .content, .intro-logo:nth-child(17) .content {animation: intro-logo-anim 0s 1.4s both;}
   .intro-logo:nth-child(16) .content {animation: intro-logo-main-anim 0s 1.5s both;}
   
   
   .intro-logo:nth-child(1), .intro-logo:nth-child(31) {animation: intro-logo-anim-off 0s 1s both;}
   .intro-logo:nth-child(2), .intro-logo:nth-child(30) {animation: intro-logo-anim-off 0s 1.1s both;}
   .intro-logo:nth-child(3), .intro-logo:nth-child(29) {animation: intro-logo-anim-off 0s 1.2s both;}
   .intro-logo:nth-child(4), .intro-logo:nth-child(28) {animation: intro-logo-anim-off 0s 1.3s both;}
   .intro-logo:nth-child(5), .intro-logo:nth-child(27) {animation: intro-logo-anim-off 0s 1.4s both;}
   .intro-logo:nth-child(6), .intro-logo:nth-child(26) {animation: intro-logo-anim-off 0s 1.5s both;}
   .intro-logo:nth-child(7), .intro-logo:nth-child(25) {animation: intro-logo-anim-off 0s 1.6s both;}
   .intro-logo:nth-child(8), .intro-logo:nth-child(24) {animation: intro-logo-anim-off 0s 1.7s both;}
   .intro-logo:nth-child(9), .intro-logo:nth-child(23) {animation: intro-logo-anim-off 0s 1.8s both;}
   .intro-logo:nth-child(10), .intro-logo:nth-child(22) {animation: intro-logo-anim-off 0s 1.9s both;}
   .intro-logo:nth-child(11), .intro-logo:nth-child(21) {animation: intro-logo-anim-off 0s 2s both;}
   .intro-logo:nth-child(12), .intro-logo:nth-child(20) {animation: intro-logo-anim-off 0s 2.1s both;}
   .intro-logo:nth-child(13), .intro-logo:nth-child(19) {animation: intro-logo-anim-off 0s 2.2s both;}
   .intro-logo:nth-child(14), .intro-logo:nth-child(18) {animation: intro-logo-anim-off 0s 2.3s both;}
   .intro-logo:nth-child(15), .intro-logo:nth-child(17) {animation: intro-logo-anim-off 0s 2.4s both;}
   .intro-logo:nth-child(16) {animation: intro-logo-main-anim-off 0.6s 2.5s both;}
   
   
   @keyframes intro-logo-anim {
       0% {
           -webkit-text-stroke: 2px transparent;
       }
       100% {
           -webkit-text-stroke: 2px #FFF;
       }
   }
   
   @keyframes intro-logo-anim-off {
       0% {
           opacity: 1;
       }
       100% {
           opacity: 0;
       }
   }
   
   @keyframes intro-logo-main-anim-off {
       0% {
           opacity: 1;
       }
       10% {
           opacity: 0;
       }
       20% {
           opacity: 1;
       }
       30% {
           opacity: 0;
       }
       40% {
           opacity: 1;
       }
       50% {
           opacity: 0;
       }
       60% {
           opacity: 1;
       }
       70% {
           opacity: 0;
       }
       75% {
           opacity: 1;
       }
       80% {
           opacity: 0;
       }
       85% {
           opacity: 1;
       }
       90% {
           opacity: 0;
       }
       95% {
           opacity: 1;
       }
       100% {
           opacity: 0;
       }
   }
   
   @keyframes intro-logo-main-anim {
       0% {
           color:  transparent;
       }
       100% {
           color:  #FFF;
       }
   }
   
   .trans-intro {width: 100%; display: block; background:#101010; bottom: 0; position:fixed; z-index:9999; animation: enter-page 2.5s both; } 
   
   @keyframes enter-page {
       0% {
           height: 100%; 
       }
       100% {
           height: 0;
       }
   }
   
   .transition {width: 100%; height: 100%; display: block; bottom: 0; position:fixed; z-index:-100; background:#101010;}
   
   #trans {width:100%; height: 0%; display: block; position:fixed; z-index:23; background: #101010; overflow: hidden; }
   
   
   .transition:after {
       content:"";
       position: absolute;
       width:200vw;
       height: 200vh;
       left: -50%;
       top: -50%;
       background-image: url('img/grain_sd.png');
       animation:grain 3s steps(6) infinite;
   }
   
   .about .transition:after, .awards .transition:after, .single-project .transition:after  {
       content:"";
       position: absolute;
       width:0;
       height: 0;
       left: -50%;
       top: -50%;
       background-image: none !important;
       animation:none !important;
   }
   
   @keyframes grain {
       0%, 100% {transform: translate(0,0);}
       10% {transform: translate(-5%,-10%);}
       30% {transform: translate(3%,-15%);}
       50% {transform: translate(12%,9%);}
       70% {transform: translate(9%,4%);}
       90% {transform: translate(-1%,7%);}
   }
   
   a, a:hover {
       text-decoration: none;
   }
   
   nav a, nav a:hover {
       text-decoration: none;
       -webkit-transition: color 1.5s cubic-bezier(0.23,1,.32,1);
       -moz-transition: color 1.5s cubic-bezier(0.23,1,.32,1);
       ms-transition: color 1.5s cubic-bezier(0.23,1,.32,1);
       -o-transition: color 1.5s cubic-bezier(0.23,1,.32,1);
       transition: color 1.5s cubic-bezier(0.23,1,.32,1);
   }
   
   header {width: 100vh;position: fixed;height: 16px;z-index: 25; mix-blend-mode: difference;}
   
   header h1 {top: 50%; position: fixed; left: 0; opacity: 1; font-weight: 800; font-family: 'Monument'; letter-spacing: 0.05em;}
   
   header h1 a{color: #FFF}
   
   header.desktop h1 {transform: rotate(-90deg) translate(0px, -50px) !important;}
   
   nav {width: 100vh;position: fixed; height: 16px; z-index: 25; mix-blend-mode: difference; font-weight: 400; font-family: 'Monument'; letter-spacing: 0.05em;}
   
   nav ul {top: 50%; position: fixed; right: 0; opacity: 1;}
   
   nav.desktop ul {transform: rotate(90deg) translate(0px, -125px) !important;}
   
   nav ul li {display: inline-block;margin: 0 20px; font-size: 16px;}
   
   nav ul li a.active {color: #FFF;}
   
   .active {pointer-events: none !important;}
   
   nav ul li a {color: #FFF; opacity: 0.5;}
   
   nav ul li a:hover, nav ul li a.active {opacity: 1;}
   
   nav,  h1 {animation: intro-nav 1.5s 2.8s both;}
   
   nav, h1 {
       -webkit-transition: all 1s cubic-bezier(0.23,1,.32,1);
       -moz-transition: all 1s cubic-bezier(0.23,1,.32,1);
       ms-transition: all 1s cubic-bezier(0.23,1,.32,1);
       -o-transition: all 1s cubic-bezier(0.23,1,.32,1);
       transition: all 1s cubic-bezier(0.23,1,.32,1);
   }
   
   
   @keyframes intro-nav {
       0% {
           opacity: 0;
       }
       100% {
           opacity: 1;
       }
   }
   
   #main{-webkit-overflow-scrolling: touch; position:absolute;}
   
   #main {width: 100%; margin:0 auto; display: block; }
   
   #content {display: block; margin: 0 auto; width: 100%;}
   
   #container-content {margin: 0 auto; width: 100%; padding: 150px 0;}
   
   h1 {font-size: 20px; color: #101010; font-weight: normal}
   h2 {font-size: 32px; line-height: 32px; color: #FFF; font-weight: bold}
   h3 {font-size: 14px; line-height: 36px; color: #606060;}
   p {font-size: 16px; line-height: 24px; color: #101010;}
   
   ul li {display: block; list-style: none; }
   
   
   @keyframes logo-animation {
       0% {
           transform: rotate(-90deg) translate(0px, 0) !important;
       }
       100% {
           transform: rotate(-90deg) translate(0px, -60px) !important;
       }
   }
   
   
   /* ==========================================================================
      ==========================================================================
      ==========================================================================
   
      Projects List
   
      ==========================================================================
      ==========================================================================
      ========================================================================== */
   
   
   ul.projects-list {width: 80%;display: block;margin: 0 auto; }
   
   
   ul.projects-list li {font-size: 70px; font-family: 'Monument'; font-weight: 400; color: #101010;text-align: center;display: block;z-index: 500;height: 105px; letter-spacing: 0.05em; }
   
   ul.projects-list li a {color: #FFF; z-index: 1500; position: relative; height: 110px; display: inline-block; line-height: 72px; -webkit-text-stroke: 1px #FFF;
       -webkit-transition: all .2s linear;
       -moz-transition: all .2s linear;
       ms-transition: all .2s linear;
       -o-transition: all .2s linear;
       transition: all .2s linear;
   }
   
   ul.projects-list.scrolling li a {
       -webkit-transition: all .2s linear;
       -moz-transition: all .2s linear;
       ms-transition: all .2s linear;
       -o-transition: all .2s linear;
       transition: all .2s linear;
   }
   
   ul.projects-list li a:hover { color: #FFF; z-index: 1500; position: relative; opacity: 1;
       -webkit-transition: all .5s cubic-bezier(0.23,1,.32,1);
       -moz-transition: all .5s cubic-bezier(0.23,1,.32,1);
       ms-transition: all .5s cubic-bezier(0.23,1,.32,1);
       -o-transition: all .5s cubic-bezier(0.23,1,.32,1);
       transition: all .5s cubic-bezier(0.23,1,.32,1);
   }
   
   ul.projects-list li h5.highlight {color: transparent; z-index: 6200; position: relative; opacity:0; margin: -110px 0; -webkit-text-stroke: 1px #FFF;
       pointer-events:none;
       -webkit-transition: all .5s cubic-bezier(0.23,1,.32,1);
       -moz-transition: all .5s cubic-bezier(0.23,1,.32,1);
       ms-transition: all .5s cubic-bezier(0.23,1,.32,1);
       -o-transition: all .5s cubic-bezier(0.23,1,.32,1);
       transition: all .5s cubic-bezier(0.23,1,.32,1);
   }
   
   ul.projects-list li h5.highlight.show {opacity: 1;  display: block;
       -webkit-transition: all .5s cubic-bezier(0.23,1,.32,1);
       -moz-transition: all .5s cubic-bezier(0.23,1,.32,1);
       ms-transition: all .5s cubic-bezier(0.23,1,.32,1);
       -o-transition: all .5s cubic-bezier(0.23,1,.32,1);
       transition: all .5s cubic-bezier(0.23,1,.32,1);
   }
   
   ul.projects-list .visual-container img {max-height: 40vh; display: block; width: auto; height: auto;}
   ul.projects-list .visual-container .imgsize {opacity: 0}
   
   
   ul.projects-list .visual-container { display: block; position: absolute; margin: 0 auto; text-align: center; overflow: hidden; background-color: #FFF; pointer-events: none; z-index: 6000; top: 0; left:50%}
   
   ul.projects-list li span { font-size: 20px; letter-spacing: 0; font-family: 'Monument'; text-transform: initial; position: absolute; text-align: center; font-weight: bold; opacity: 0; margin: -15px auto; width: 80%; display: block; z-index: 6500; color:#FFF; pointer-events:none;  ;
       -webkit-transition: all .5s cubic-bezier(0.23,1,.32,1);
       -moz-transition: all .5s cubic-bezier(0.23,1,.32,1);
       ms-transition: all .5s cubic-bezier(0.23,1,.32,1);
       -o-transition: all .5s cubic-bezier(0.23,1,.32,1);
       transition: all .5s cubic-bezier(0.23,1,.32,1);
   }
   
   ul.projects-list li span.show { opacity: 1; margin: -40px auto;
       -webkit-transition: all 1s 0.5s cubic-bezier(0.23,1,.32,1);
       -moz-transition: all 1s 0.5s cubic-bezier(0.23,1,.32,1);
       ms-transition: all 1s 0.5s cubic-bezier(0.23,1,.32,1);
       -o-transition: all 1s 0.5s cubic-bezier(0.23,1,.32,1);
       transition: all 1s 0.5s cubic-bezier(0.23,1,.32,1);
   }
   
   /* ==========================================================================
      ==========================================================================
      ==========================================================================
   
      About
   
      ==========================================================================
      ==========================================================================
      ========================================================================== */
   
   .about .transition {background: #FFF;}
   .about .project {color: #101010; padding-top: 0vw;}
   
   .about h4 { padding: 0; font-size: 24px; line-height: 90px; height: 90px; float: left; padding-left: 0%; font-weight: 200; width: 15%; color: #c4ac8a;}
   .about .bio p {font-family: 'Monument'; font-size: 48px; color: #101010; font-weight: 200; margin-bottom: 20px; line-height: 60px; padding-left: 5%;}
   .about .bio p span a { font-size: 24px; color: #c4ac8a; font-weight: 200; margin-bottom: 20px; line-height: 46px;}
   .about .bio, .about .clients {display: block; width: 100%; margin-bottom: 50px; z-index: 10;}
   .about .bio {width: 80% !important; margin-top: calc(30vh - 250px) ;}
   .about .team {display: block; position: absolute; float: right; right:10%; top:50px; z-index: -10;}
   .about .team img {width: auto; opacity: 0.7; height: 50vh;}
   .about ul {margin-bottom: 24px;}
   .about ul.client-list {width: 65%; float: right; }
   .about .client-list ul {columns: 4;}
   .about .agency-list {width: 20%; float: left; margin-left: 15%; }
   .about h6 {font-size: 16px; color: #c4ac8a; line-height: 48px; }
   .about li {font-size: 16px; color: #101010; line-height: 32px; display: block; }
   
   /* ==========================================================================
      ==========================================================================
      ==========================================================================
   
      Awards
   
      ==========================================================================
      ==========================================================================
      ========================================================================== */
   
   .awards .transition {background: #FFF;}
   .awards .project {color: #101010; padding-top: 1vw;}
   .awards .awards-list {width: 100%; position: absolute; right: 0; padding-bottom: 150px;}
   .awards .awards-list ul.award-item {border-top: 1px solid #DDD ; width: 100%; margin-top: -1px; float: left;}
   .awards .awards-list ul.award-item:last-child {border-bottom: 1px solid #DDD ;}
   .awards .awards-list ul.award-item:last-child .project-title {border-bottom: 0 ;}
   .awards .awards-list li.awards-count {font-family: 'Monument'; width: 100%; padding: 0; font-size: 36px; line-height: 90px; height: 90px; float: right; padding-left: 0%; font-weight: 200; color: #c4ac8a;}
   .awards .awards-list li.awards-count span {font-weight: 200;}
   .awards li.project-title {font-size: 16px; color: #606060; margin-top: 50px; font-weight: normal;  float: left; width: 40% ; text-align: left; padding-left: 0%;}
   .awards li.project-title span {text-align: left; width: 15%; left: 0; float: left; margin-right: 8px; font-size: 15px; }
   .awards li:first-child {margin-top: 0;}
   .awards li:last-child {margin-bottom: 0px; border-bottom: 0; }
   .awards li:nth-child(2) {width: 60%; padding-left: 0%;}
   .awards li {font-size: 16px; color: #101010; line-height: 48px; display: block; float: right; width: 100%; border-bottom: 1px solid #DDD ; box-sizing:border-box;  -moz-box-sizing:border-box;  -webkit-box-sizing:border-box; height: 48px; padding-left: 40%; white-space: nowrap; overflow: hidden;}
   
   /*   .about li::after {content: '|'; color: #202020; margin: 0 10px; font-size: 16px;}
      .about li:last-child::after {content: ''; color: #AAA; margin-right: 0;}*/
   
   
   /* ==========================================================================
      ==========================================================================
      ==========================================================================
   
      Projects Page
   
      ==========================================================================
      ==========================================================================
      ========================================================================== */
   
   .project .title, .project .img, .project .video, .project .thumbnail, .project iframe, .project .play {
       -webkit-transition: opacity 1s cubic-bezier(0.23,1,.32,1);
       -moz-transition: opacity 1s cubic-bezier(0.23,1,.32,1);
       ms-transition: opacity 1s cubic-bezier(0.23,1,.32,1);
       -o-transition: opacity 1s cubic-bezier(0.23,1,.32,1);
       transition: opacity 1s cubic-bezier(0.23,1,.32,1);
   }
   
   .project {display: block; position: relative; margin:0 auto; width: 80%; max-width: 1280px; }
   .project h2, .project h3 {text-align: center;}
   .project .title, .project .img, .project .video, .project .grid-img {display: block; width: 80vw; max-width: 1280px; margin: 0 auto; display: block; margin-bottom: 50px; position: relative; font-family: 'Monument';}
   .project img {width: 100%; margin: 0 auto; display: block;}
   .project .video {width: 80vw; height: 45vw; max-width: 1280px; max-height: 720px; overflow: hidden;}
   .project iframe {position:absolute;top:0;left:0;width: 80vw; height: 45vw; max-width: 1280px; max-height: 720px; opacity: 1; z-index: 10; display: block;}
   .project iframe.show {opacity: 1; z-index: 100 !important}
   .project .thumbnail {position: relative;}
   .play {background: url('img/play.svg') center center no-repeat; display: block; width: 100%; height: 100%; z-index: 20; top: 0; cursor: pointer; position: absolute; opacity: 1}
   .preload .play {opacity: 0 !important;}
   .hide {opacity: 0; }
   .credits, .awards {width: 50%; float: left; position: relative;}
   .infos h4 {font-size: 14px; line-height: 18px; margin-bottom: 20px; color: #606060}
   .infos li {font-size: 16px;line-height: 24px;}
   .infos li span {color: #aaa}
   .infos li span:after {content:' – ';}
   .project .grid-img.three .img {display: block; width: 30%; position: relative; float: left; margin-right: 5%;}
   .project .grid-img.two .img {display: block; width: 47.5%; position: relative; float: left; margin-right: 5%;}
   .project .grid-img.three .img:nth-child(3) {margin-right: 0%;}
   .project .grid-img.two .img:nth-child(2) {margin-right: 0%;}
   .project .right {float: right; position: relative; display: block}
   .project .left {float: left; position: relative; display: block}
   .project .random {margin-bottom: 150px; }
   .project .random .img {width: 100% !important; position: relative; display: block; margin-bottom: 50px;}
   .project .random .img img {margin-bottom: 0px;}
   .project .random .video {width: 56vw; height: 31.5vw; max-width: 1280px; max-height: 720px; overflow: hidden;}
   .project .random.size50 img, .project .random.size50 .info {width: 50% !important; display: block }
   .project .random.size60 img, .project .random.size60 .info {width: 60% !important; display: block }
   .project .random.size70 img, .project .random.size70 .info {width: 70% !important; display: block }
   .project .random.size80 img, .project .random.size80 .info {width: 80% !important; display: block }
   .project .random.size90 img, .project .random.size90 .info {width: 90% !important; display: block }
   .project .random .img:nth-child(1) img, .project .random .video:nth-child(1) {float: left;}
   .project .random .img:nth-child(2) img, .project .random .video:nth-child(2) {float: right;}
   .project .random .img:nth-child(3) img, .project .random .video:nth-child(3) {float: left;}
   .project .random .img:last-child img, .one-item img, .project .random .video:last-child, .one-item .video {margin-bottom: 0 !important;}
   .project .random .img img {margin-bottom: 50px;}
   .project .random .info {margin-top: 20px;}
   .project .random.two-items .info {float: right; }
   .project .random.one-item .info, .project .random.three-items .info {float: left; }
   .project .random h2, .project .random h3 {text-align: left;}
   .project .random h2 {font-size: 24px; line-height: 28px; color: #101010; font-weight: 800; font-family: 'Monument'; }
   .project .random h3 {font-size: 13px; line-height: 28px; color: #808080; font-family: 'Monument'; }
   .project .credits, .project .prix {display: block; width: 50%; float: left; margin-bottom: 50px;}
   .project .credits h3, .project .prix h3 {font-family: 'Monument'; font-size: 13px; line-height: 20px; color: #808080; text-align: left; margin-bottom: 10px;}
   .project .credits li, .project .prix li {font-size: 14px; color: #101010; line-height: 24px; list-style: none }
   .project .credits li span, .project .prix li span {color: #808080; }
   .single-project .transition {background: #FFF;}
   .project h2 { color: #101010;}
   
   
   
   
   
   
   /* ==========================================================================
      ==========================================================================
      ==========================================================================
   
      Transitions Pages
   
      ==========================================================================
      ==========================================================================
      ========================================================================== */
   
   /* Transition ENTER */
   
   .page-slide-enter #trans {
       height: 100%;
       bottom: 0;
       transform-origin: bottom;
       z-index: -2000;
   }
   
   .page-slide-enter.page-slide-enter-active #trans {
       z-index: 24;
       height: 0;
       -webkit-transition: height 1s 1.5s cubic-bezier(0.23,1,.32,1), z-index 0s .5s ;
       -moz-transition: height 1s 1.5s cubic-bezier(0.23,1,.32,1), z-index 0s .5s ;
       ms-transition: height 1s 1.5s cubic-bezier(0.23,1,.32,1), z-index 0s .5s ;
       -o-transition: height 1s 1.5s cubic-bezier(0.23,1,.32,1), z-index 0s .5s ;
       transition: height 1s 1.5s cubic-bezier(0.23,1,.32,1), z-index 0s .5s ;
   }
   
   /* Index */
   
   .page-slide-enter #container-content.index {
       opacity: 0;
       padding: 300px 0 150px 0 ;
   }
   
   .page-slide-enter.page-slide-enter-active #container-content.index {
       opacity: 1;
       padding: 150px 0;
       -webkit-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       -moz-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       ms-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       -o-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
   }
   
   /* Prehome */
   
   .page-slide-enter #container-content.prehome {
       opacity: 0;
       padding: 300px 0 150px 0 ;
   }
   
   .page-slide-enter.page-slide-enter-active #container-content.prehome {
       opacity: 1;
       padding: 150px 0;
       -webkit-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       -moz-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       ms-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       -o-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
   }
   
   
   
   /* Projet */
   
   .page-slide-enter #container-content.single-project {
       opacity: 0;
       padding: 300px 0 150px 0 ;
   }
   
   .page-slide-enter.page-slide-enter-active #container-content.single-project {
       opacity: 1;
       padding: 150px 0;
       -webkit-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       -moz-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       ms-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       -o-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
   }
   
   
   
   /* About */
   
   .page-slide-enter #container-content.about .project, .page-slide-enter #container-content.about img {
       transform: translateY(100px);
       opacity: 0;
   }
   
   .page-slide-enter.page-slide-enter-active  #container-content.about .project {
       transform: translateY(0px);
       opacity: 1;
       -webkit-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       -moz-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       ms-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       -o-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
   }
   
   .page-slide-enter #container-content.about .team img {
       transform: translateY(300px);
       opacity: 0;
   }
   
   .page-slide-enter.page-slide-enter-active  #container-content.about .team img {
       transform: translateY(0px);
       opacity: 0.7;
       -webkit-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       -moz-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       ms-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       -o-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
   }
   
   
   
   /* Awards */
   
   .page-slide-enter #container-content.awards .project {
       transform: translateY(100px);
       opacity: 0;
   }
   
   .page-slide-enter.page-slide-enter-active  #container-content.awards .project {
       transform: translateY(0px);
       opacity: 1;
       -webkit-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       -moz-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       ms-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       -o-transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
       transition: all 1s 1.5s cubic-bezier(0.23,1,.32,1);
   }
   
   /* Transition EXIT */
   
   /* GLOBAL */
   
   .page-slide-exit #trans {
       opacity: 1;
       height: 0;
       top: 0;
       transform-origin: top center;
   }
   
   .page-slide-exit.page-slide-exit-active #trans {
       height: 100%;
       opacity: 0;
       -webkit-transition: height 1s cubic-bezier(0.23,1,.32,1), opacity 0s 1.5s;
       -moz-transition: height 1s cubic-bezier(0.23,1,.32,1), opacity 0s 1.5s;
       ms-transition: height 1s cubic-bezier(0.23,1,.32,1), opacity 0s 1.5s;
       -o-transition: height 1s cubic-bezier(0.23,1,.32,1), opacity 0s 1.5s;
       transition: height 1s cubic-bezier(0.23,1,.32,1), opacity 0s 1.5s;
   }
   
   .page-slide-exit.page-slide-exit-active #container-content {
       padding: 0;
       opacity: 0;
       -webkit-transition: padding 1s cubic-bezier(0.23,1,.32,1), opacity 0s 1.5s;
       -moz-transition: padding 1s cubic-bezier(0.23,1,.32,1), opacity 0s 1.5s;
       ms-transition: padding 1s cubic-bezier(0.23,1,.32,1), opacity 0s 1.5s;
       -o-transition: padding 1s cubic-bezier(0.23,1,.32,1), opacity 0s 1.5s;
       transition: padding 1s cubic-bezier(0.23,1,.32,1), opacity 0s 1.5s;
   }
   
   .page-slide-exit.page-slide-exit-active #container-content .projects-list, .page-slide-exit.page-slide-exit-active #container-content .project {
       opacity: 0;
       -webkit-transition: all 1s cubic-bezier(0.23,1,.32,1);
       -moz-transition: all 1s cubic-bezier(0.23,1,.32,1);
       ms-transition: all 1s cubic-bezier(0.23,1,.32,1);
       -o-transition: all 1s cubic-bezier(0.23,1,.32,1);
       transition: all 1s cubic-bezier(0.23,1,.32,1);
   }
   
   /* Debug */
   
   .page-slide-exit a, .page-slide-exit a:hover {
       pointer-events: none !important;
   }
   
   
   
   /* ==========================================================================
      ==========================================================================
      ==========================================================================
   
      Media Queries
   
      ==========================================================================
      ==========================================================================
      ========================================================================== */
   
   @media screen and (min-width: 320px) and (max-width: 767px) /*and (orientation: portrait*/ {
   
       .videostory video {display: none !important}
   
       .page-slide-enter #container-content.index { padding: 100px 0 ; }
       .page-slide-enter.page-slide-enter-active #container-content.index { padding: 50px 0; }
   
       /* Projet */
       .page-slide-enter #container-content.single-project { padding: 100px 0 ; }
       .page-slide-enter.page-slide-enter-active #container-content.single-project { padding: 50px 0; }
       .project .credits, .project .prix {display: block; width: 100%; float: left; margin-bottom: 20px;}
       ul.projects-list .visual-container, ul.projects-list h5, ul.projects-list span {display: none !important;}
       .desktop, .projects-list h5, .projects-list h5.show, .visual-container, .projects-list span {display: none !important; z-index: -100 !important; opacity: 0 !important}
       .preload ul.projects-list li, ul.projects-list.scrolling li a {text-shadow:none !important;}
       ul.projects-list {width: 100%; padding: 0 24px; box-sizing:border-box; -moz-box-sizing:border-box; -webkit-box-sizing:border-box; }
       ul.projects-list li a {line-height: 44px; height: 44px; display: block; white-space: nowrap; }
       ul.projects-list li {font-size: 24px;height: 44px;line-height: 44px;}
       .credits, .awards {width: 100%; margin-bottom: 16px; }
       .infos h4 {font-size: 12px; line-height: 16px; margin-bottom: 4px;}
       .infos li {font-size: 14px; line-height: 20px;}
       .project .title, .project .img, .project .video {margin-bottom: 20px;}
       .project .random .video {width: 90vw; height: 50vw; max-width: 1280px; max-height: 720px; overflow: hidden;}
       .project .random {margin-bottom: 80px; }
       #content {padding: 24px 0 80px 0;}
       header.mobile {position: relative; width: 50%; left:24px; margin:0; float: left; height: auto; display: block; transform: translateZ(0)}
       header.mobile h1 {position: relative; width: 100%; font-size: 12px; line-height: 24px}
       nav.mobile {position: relative; width: 50%; float: right; height: 24px; right:24px; transform: translateZ(0) }
       nav.mobile ul {position: relative; width: 100%; text-align: right; top: 0}
       nav.mobile ul li {margin: 0 0 0 8px; font-size: 11px; line-height: 24px}
       #container-content {margin-top: 50px; padding-top: 50px;}
       .about .project {padding-top: 1vh}
       .about .bio p { font-size: 18px !important; margin-bottom: 20px; line-height: 24px !important; padding: 0;}
       .about .bio {width: 100% !important; margin: 0 ;}
       .about .bio p span a { font-size: 18px !important; line-height: 30px;}
       .about .team {display: block; position: relative; width: 100%; padding: 0 24px; right: auto; box-sizing:border-box; -moz-box-sizing:border-box; -webkit-box-sizing:border-box; top:0 !important; margin-bottom: 20px;}
       .about .team img {opacity: 0.7; height: auto; width: 100%; }
       .about ul {margin-bottom: 24px;}
       .about ul.client-list {width: 100%; float: right; }
       .about ul {columns: 2 !important;}
       .about .agency-list {width: 100%; float: left; margin-left: 0; }
       .about h6 {font-size: 14px; color: #c4ac8a; line-height: 36px; }
       .about li {font-size: 14px; color: #101010; line-height: 24px; display: block; }
       .project {width: 100%; padding: 0 24px; box-sizing:border-box;  -moz-box-sizing:border-box;  -webkit-box-sizing:border-box;}
       .project .video {overflow: hidden;}
       .project iframe {width: 100%; height: 100%; }
       .awards .awards-list {padding: 0 24px; box-sizing:border-box;  -moz-box-sizing:border-box;  -webkit-box-sizing:border-box; }
       .awards .awards-list li.awards-count {font-size: 20px; line-height: 42px; height: 42px;}
       .awards li.project-title {font-size: 12px; width: 100%; padding: 0;}
       .awards li.project-title span {font-size: 12px; width: auto;}
       .awards li:nth-child(2) {width: 100%; padding-left: 0;}
       .awards li {font-size: 12px; line-height: 36px; height: 36px; padding-left: 0;}
       .project .grid-img .img {display: block; width: 100% !important; position: relative; float: none !important; margin-right: 0 !important;}
       .project h2 {font-size: 20px; line-height: 24px;}
       .project h3 {font-size: 14px; line-height: 20px;}
       .project .random.size50 img, .project .random.size50 .info, .project .random.size60 img, .project .random.size60 .info, .project .random.size70 img, .project .random.size70 .info, .project .random.size80 img, .project .random.size80 .info, .project .random.size90 img, .project .random.size90 .info {width: 100% !important; display: block }
       .content-logo {position: absolute; top: 50%; left: 0; transform:translate(0%, -50%); width: 100%; }
       .intro-logo .content {color:transparent; ; font-family: "Monument"; font-weight: 800; font-size: 30px; letter-spacing: 0.05rem;  }
   
   }
   
   
   @media screen and (min-width: 768px) and (max-width: 1180px) {
   
       .mobile {display: none;}
       ul.projects-list li a {height: 78px; line-height: 76px}
       ul.projects-list li {font-size: 56px;height: 78px;}
       ul.projects-list li h5 {font-size: 56px; margin-top: -69px !important;}
       ul.projects-list li span.show {margin-top: -10px !important;}
       ul.projects-list li span {margin-top: 0px !important; font-size: 16px; }
       .infos h4 {font-size: 12px; line-height: 16px; margin-bottom: 16px;}
       .infos li {font-size: 14px;line-height: 20px;}
       .project .title, .project .img, .project .video {margin-bottom: 40px; }
       #content {padding: 120px 0;}
       header.desktop h1 {transform: rotate(-90deg) translate(0px, -50px) !important;}
       nav.desktop ul {transform: rotate(90deg) translate(0px, -125px) !important;}
       .about .bio p, .about .bio p span {font-size: 36px; line-height: 46px; }
       .about h6 {font-size: 14px; color: #c4ac8a; line-height: 36px; }
       .about li {font-size: 14px; color: #101010; line-height: 24px; display: block; }
       .about .client-list ul {columns: 3;}
       .awards .awards-list {right: auto; padding: 0 5%; box-sizing:border-box;  -moz-box-sizing:border-box;  -webkit-box-sizing:border-box;}
       .awards li.project-title {font-size: 14px;}
       .awards li.project-title span {font-size: 14px; width: auto;}
       .awards li {font-size: 14px;}
   
   }
   
   @media screen and (min-width: 768px) {
   
       .mobile {display: none; }
   
   }
   
   
   
   
   /* ==========================================================================
      ==========================================================================
      ==========================================================================
   
      Helper classes
   
      ==========================================================================
      ==========================================================================
      ========================================================================== */
   
   .hidden {
       display: none !important;
       visibility: hidden;
   }
   
   .visuallyhidden {
       border: 0;
       clip: rect(0 0 0 0);
       height: 1px;
       margin: -1px;
       overflow: hidden;
       padding: 0;
       position: absolute;
       width: 1px;
   }
   
   .visuallyhidden.focusable:active,
   .visuallyhidden.focusable:focus {
       clip: auto;
       height: auto;
       margin: 0;
       overflow: visible;
       position: static;
       width: auto;
   }
   
   .invisible {
       visibility: hidden;
   }
   
   .clearfix:before,
   .clearfix:after {
       content: " ";
       display: table;
   }
   
   .clearfix:after {
       clear: both;
   }
   
   .clearfix {
       *zoom: 1;
   }
   
   
   /* ==========================================================================
      ==========================================================================
      ==========================================================================
   
      Glitch Effect - Projects List
   
      ==========================================================================
      ==========================================================================
      ========================================================================== */
   
   .visual-effect {
       position: absolute;
       top: calc(-1 * 2px);
       left: calc(-1 * 10px);
       width: calc(100% + 10px * 2);
       height: calc(100% + 2px * 2);
       transform: translate3d(0,0,0);
       background-position: 50% 50%;
   }
   
   
   
   /* ==========================================================================
      ==========================================================================
      ==========================================================================
   
      Print styles
   
      ==========================================================================
      ==========================================================================
      ========================================================================== */
   
   @media print {
       *,
       *:before,
       *:after {
           background: transparent !important;
           color: #101010 !important;
           box-shadow: none !important;
           text-shadow: none !important;
       }
   
       a,
       a:visited {
           text-decoration: underline;
       }
   
       a[href]:after {
           content: " (" attr(href) ")";
       }
   
       abbr[title]:after {
           content: " (" attr(title) ")";
       }
   
       a[href^="#"]:after,
       a[href^="javascript:"]:after {
           content: "";
       }
   
       pre,
       blockquote {
           border: 1px solid #999;
           page-break-inside: avoid;
       }
   
       thead {
           display: table-header-group;
       }
   
       tr,
       img {
           page-break-inside: avoid;
       }
   
       img {
           max-width: 100% !important;
       }
   
       p,
       h2,
       h3 {
           orphans: 3;
           widows: 3;
       }
   
       h2,
       h3 {
           page-break-after: avoid;
       }
   }
   
   .show {
       opacity: 1;
   }
   
   .hide {
       opacity: 0;
   }
   